<template>
  <div>
    <div class="position-absolute w-100 h-100 top-0 start-0 bottom-0 end-0">
      <GmapMap
        ref="mapFullRef"
        class="w-100 h-100"
        v-bind:options="options"
        :zoom="10"
        :center="{ lat: 38, lng: -2 }"
      >
      </GmapMap>
    </div>

    <div
      class="app-content-padding position-relative"
      style="background: rgba(80, 80, 80, 0.5)"
    >
      <ol class="breadcrumb float-xl-end">
        <li class="breadcrumb-item">
          <a
            href="javascript:;"
            class="text-white"
            @click="showControles = !showControles"
            >Controles
            <i v-if="!showControles" class="fa fa-caret-down me-1"></i
            ><i v-else class="fa fa-caret-up me-1"></i
          ></a>
        </li>
      </ol>
      <!-- BEGIN page-header -->
      <h1 class="page-header text-white">
        Mapa
        <small class="text-white">{{ finca }}</small>
      </h1>
      <!-- END page-header -->

      <div>
        <div v-show="showControles">
          <!-- BEGIN row -->
          <div class="row">
            <!-- BEGIN col-6 -->
            <div class="col-sm-6">
              <!-- BEGIN card -->
              <div
                class="card border-0 text-truncate mb-3 bg-gray-800 text-white"
              >
                <!-- BEGIN card-body -->
                <div class="card-body">
                  <!-- BEGIN title -->
                  <div class="mb-3 text-gray-500">
                    <b class="mb-3">CONVERSION RATE</b>
                    <span class="ms-2"
                      ><i
                        class="fa fa-info-circle"
                        title="Conversion Rate"
                        v-b-popover.hover="
                          'Percentage of sessions that resulted in orders from total number of sessions.'
                        "
                      ></i
                    ></span>
                  </div>
                  <!-- END title -->
                  <!-- BEGIN info-row -->
                  <div class="d-flex mb-2">
                    <div class="d-flex align-items-center">
                      <i class="fa fa-circle text-red fs-8px me-2"></i>
                      Added to cart
                    </div>
                    <div class="d-flex align-items-center ms-auto">
                      <div class="text-gray-500 small">
                        <i class="fa fa-caret-up"></i> 262%
                      </div>
                      <div class="w-50px text-end ps-2 fw-bold">3.79%</div>
                    </div>
                  </div>
                  <!-- END info-row -->
                  <!-- BEGIN info-row -->
                  <div class="d-flex mb-2">
                    <div class="d-flex align-items-center">
                      <i class="fa fa-circle text-warning fs-8px me-2"></i>
                      Reached checkout
                    </div>
                    <div class="d-flex align-items-center ms-auto">
                      <div class="text-gray-500 small">
                        <i class="fa fa-caret-up"></i> 11%
                      </div>
                      <div class="w-50px text-end ps-2 fw-bold">3.85%</div>
                    </div>
                  </div>
                  <!-- END info-row -->
                  <!-- BEGIN info-row -->
                  <div class="d-flex">
                    <div class="d-flex align-items-center">
                      <i class="fa fa-circle text-lime fs-8px me-2"></i>
                      Sessions converted
                    </div>
                    <div class="d-flex align-items-center ms-auto">
                      <div class="text-gray-500 small">
                        <i class="fa fa-caret-up"></i> 57%
                      </div>
                      <div class="w-50px text-end ps-2 fw-bold">2.19%</div>
                    </div>
                  </div>
                  <!-- END info-row -->
                </div>
                <!-- END card-body -->
              </div>
              <!-- END card -->
            </div>
            <!-- END col-6 -->
          </div>
          <!-- END row -->
        </div>
      </div>
    </div>

    <!-- <div class="pt-1 position-relative">
      <div class="d-inline-flex">
        <div class="mb-3 d-flex flex-column">
          <label
            for="manual-1"
            class="form-label text-white"
            style="z-index: 9999 !important"
            >El Romeral</label
          >
          <select-button
            id="manual-1"
            :texts="{ 1: 'Manual', 0: 'Automático' }"
            :classNames="{ 1: 'warning', 0: 'primary' }"
            :tags="{ 1: 'manual', 0: 'manual' }"
            :actions="{ 1: 'set', 0: 'reset' }"
          ></select-button>
        </div>
        <div class="mb-3 d-flex flex-column">
          <label
            for="manual-2"
            class="form-label text-white"
            style="z-index: 9999 !important"
            >El Guarda</label
          >
          <select-button
            id="manual-2"
            :texts="{ 1: 'Manual', 0: 'Automático' }"
            :classNames="{ 1: 'warning', 0: 'primary' }"
            :tags="{ 1: 'manual_2', 0: 'manual_2' }"
            :actions="{ 1: 'set', 0: 'reset' }"
          ></select-button>
        </div>
        <div class="mb-3 d-flex flex-column">
          <label for="modo" class="form-label text-white">Modo</label>
          <select-button
            id="modo"
            :texts="{ 1: 'Simulación', 0: 'Real' }"
            :classNames="{ 1: 'danger', 0: 'success' }"
            :tags="{ 1: 'simulacion', 0: 'simulacion' }"
            :actions="{ 1: 'set', 0: 'reset' }"
          ></select-button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import AppOptions from "../config/AppOptions.vue";
import * as THREE from "three/build/three.module.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { gmapApi } from "vue2-google-maps";

import { mapGetters, mapActions } from "vuex";
import { drawItem, updateItem, initWebglOverlayView } from "@/helpers/maps";

export default {
  name: "Map",
  components: {
    ...(() => {
      const context = require.context("@/components/widgets", false, /.*.vue/);
      return Object.fromEntries(
        context
          .keys()
          .map((k) => context(k).default)
          .filter((c) => c != undefined)
          .map((c) => [c.name, c])
      );
    })(),
  },
  created() {
    AppOptions.appContentFullHeight = true;
    AppOptions.appContentClass = "p-0 position-relative";
  },
  data() {
    return {
      map: null,
      centro: null,
      items: [],
      items3d: [],
      webgl: null,
      showControles: false,
      options: {
        disableDefaultUI: true,
        mapId: "b93b21f674f7e4c9",
        mapTypeId: "satellite",
        scaleControl: true,
        heading: 0,
        tilt: 60,
        rotateControl: true,
        zoom: 15,
      },
      transactions: 0,
    };
  },
  mounted() {
    if (this.currentDevice) {
      this.setMapa();
      this.getItems();
    }
    localStorage.setItem("screen", "map");
  },
  beforeDestroy() {
    this.removeItems();
  },
  computed: {
    ...mapGetters("devices", ["currentDevice", "getValue", "bufferChanged"]),
    ...mapGetters("auth", ["currentUser"]),
    finca: function () {
      if (!this.currentDevice) return "";
      return this.currentDevice.name
        ? this.currentDevice.name
        : '<i class="fas fa-spinner fa-spin"></i>';
    },
    google: gmapApi,
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appContentFullHeight = false;
    AppOptions.appContentClass = "";
    next();
  },
  methods: {
    ...mapActions("devices", ["getMarkers", "setValue"]),
    setMapa() {
      if (this.currentDevice) {
        this.$refs.mapFullRef.$mapPromise.then((map) => {
          const opciones = {
            disableDefaultUI: true,
            mapId: "b93b21f674f7e4c9",
            mapTypeId: "satellite",
            scaleControl: true,
            rotateControl: true,
            heading: this.currentDevice.position.rotation,
            tilt: this.currentDevice.position.tilt,
            zoom: this.currentDevice.position.zoom,
          };
          this.options = { ...opciones };
          map.setOptions(opciones);
          this.map = map;
          this.map.panTo(this.currentDevice.position);
        });
      }
    },
    toogleIndicadores() {
      this.showConsignas = false;
      this.showControles = !this.showControles;
    },
    toogleConsignas() {
      this.showControles = false;
      this.showConsignas = !this.showConsignas;
    },
    getItems() {
      this.items.forEach((item) => {
        item.setMap(null);
      });
      this.getMarkers({
        user: this.currentUser,
        id: this.currentDevice.id,
      }).then((data) => {
        if (!data.ok) return;
        //console.log(data.markers);
        data.markers.forEach((marker) => {
          try {
            drawItem(marker.marker, this.google, this.map, (i) => {
              i.addListener("click", () => {
                this.clickMarker(i);
              });
              this.items.push(i);
            });
          } catch (e) {
            console.error(e);
          }
        });
        this.webgl = initWebglOverlayView(
          this.items,
          this.currentDevice,
          this.map,
          this.google,
          THREE,
          GLTFLoader
        );
      });
    },
    removeItems() {
      this.items.forEach((item) => {
        item.setMap(null);
        if (!["pivot"].includes(item.subType)) return;
        if (item.gltfOff) {
          this.webgl.scene.remove(this.webgl.scene.getObjectByName("PivotOff"));
        }
        if (item.gltfOn) {
          this.webgl.scene.remove(this.webgl.scene.getObjectByName("PivotOn"));
        }
      });

      //if (this.webgl) this.webgl.webglOverlayView.setMap(null);
      this.items = [];
    },
    clickMarker(marker) {
      const action = marker.action;
      const register = marker.writeReg;
      const value = this.getValue(marker.reg);
      let question = "no definido";
      switch (action) {
        case "toggle":
          question = value
            ? `¿Desactivar ${marker.name}?`
            : `¿Activar  ${marker.name}?`;
          break;
        case "set":
          question = `¿Activar ${marker.name}?`;
          break;
        case "reset":
          question = `¿Desactivar ${marker.name}?`;
          break;
      }
      this.$bvModal
        .msgBoxConfirm(question, {
          title: "¡Atención!",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Confirmar",
          cancelTitle: "Cancelar",
          footerClass: "p-2",
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          hideHeaderClose: true,
          centered: true,
        })
        .then((resp) => {
          if (!resp) return;
          switch (action) {
            case "toggle":
              this.setValue({ register, values: value ? 0 : 1 });
              break;
            case "set":
              this.setValue({ register, values: 1 });
              break;
            case "reset":
              this.setValue({ register, values: 0 });
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      const h = this.$createElement;
      // Using HTML string
      const titleVNode = h("div", {
        domProps: { innerHTML: `${marker.name}` },
      });
      // More complex structure
      const messageVNode = h("div", { class: ["mb-3"] }, [
        h("input", {
          class: ["form-control"],
          attrs: { type: "number", value: marker.value },
          ref: "value",
        }),
      ]);
      // We must pass the generated VNodes as arrays
      this.$bvModal
        .msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: "sm",
          centered: true,
          okVariant: "danger",
          okTitle: "Confirmar",
          cancelTitle: "Cancelar",
          footerClass: "p-2",
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          hideHeaderClose: true,
          size: "sm",
        })
        .then((resp) => {
          if (!resp) return;
          switch (action) {
            case "set":
              this.setValue({ register, values: this.$refs.value.value });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateItems() {
      if(!this.webgl) return;
      for (let i = 0; i < this.items.length; i++) {
        updateItem(this.items[i], this.google, this.getValue);
      }
    },
  },
  watch: {
    currentDevice: function (n, o) {
      if (!o && n) {
        this.removeItems();
        this.getItems();
        this.setMapa();
        return;
      }
      if (n.name != o.name) {
        this.removeItems();
        this.setMapa();
        this.getItems();
      }
    },
    bufferChanged(nv) {
      this.updateItems();
      this.transactions = nv;
    },
  },
};
</script>

<style scoped>
.ddown {
  cursor: pointer;
}
</style>
