<template>
  <div class="col-xl-4 col-lg-6">
    <panel
      :title="options.title || 'Panel de control'"
      bodyClass="p-0 bg-dark"
      @expand="setPanelHeight"
    >
      <div class="list-group">
        <a
          href="javascript:;"
          class="
            list-group-item
            rounded-0
            list-group-item-action list-group-item-inverse
            d-flex
            justify-content-between
            align-items-center
            text-ellipsis
          "
        >
          {{ systemMode }}
          <span class="d-flex flex-wrap">
            <icon-push-button
              class="me-2"
              :icons="{ 0: 'fa fa-hand' }"
              :colors="{ 0: 'bg-gray', 1: 'bg-primary' }"
              :tags="options.manual"
              :actions="{ 0: 'set' }"
            ></icon-push-button>
            <icon-push-button
              :icons="{ 0: 'fa fa-circle-play' }"
              :colors="{ 0: 'bg-gray', 1: 'bg-primary' }"
              :tags="options.automatic"
              :actions="{ 0: 'reset' }"
            ></icon-push-button>
          </span>
        </a>
        <vue-custom-scrollbar :style="'height: ' + height">
          <a
            v-for="(control, index) in options.controls"
            :key="index"
            href="javascript:;"
            class="
              list-group-item list-group-item-action list-group-item-inverse
              d-flex
              justify-content-between
              align-items-center
              text-ellipsis
            "
          >
            {{ control.title }}
            <span class="d-flex flex-wrap">
              <icon-push-button
                :tags="control.tags"
                :actions="{ 0: 'toggle' }"
              ></icon-push-button>
            </span>
          </a>
        </vue-custom-scrollbar>
      </div>
    </panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PanelManualAuto",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ready: 0,
      manual: 0,
      automatic: 0,
      controls: [],
      transactions: 0,
      panelHeight: "260px",
    };
  },
  methods: {
    setPanelHeight(expand) {
      if (expand) this.panelHeight = "100%";
      else this.panelHeight = "260px";
    },
  },
  computed: {
    ...mapGetters("devices", ["getValue", "bufferChanged"]),
    systemMode() {
      if (!this.ready) {
        return "Sistema no activo";
      }
      return this.manual ? "Modo manual" : "Modo automático";
    },
    height() {
      return this.panelHeight;
    },
  },
  watch: {
    bufferChanged(nv) {
      if (this.options.ready) this.ready = this.getValue(this.options.ready[0]);
      if (this.options.manual)
        this.manual = this.getValue(this.options.manual[0]);
      if (this.options.automatic[0])
        this.automatic = this.getValue(this.options.automatic[0]);
      this.options.controls.forEach((control, index) => {
        if (!this.controls[index]) this.controls[index] = 0;
        this.controls[index] = this.getValue(control.tags[0]);
      });
      this.transactions = nv;
    },
  },
};
</script>

<style></style>
