<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
      <li class="breadcrumb-item active">Panel de control</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">
      Panel de control <small>{{ finca }}</small>
    </h1>
    <!-- END page-header -->
    <component
      v-for="(row, index) in template"
      :is="'Row'"
      :children="row.children"
      :key="'row-' + index"
    ></component>
    <!-- BEGIN row -->
    <div class="row">
      <!-- BEGIN col-8 -->
      <component :is="'ColumnChart'" :data="{}"></component>
      <!-- END col-8 -->
    </div>
    <!-- END row -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getItems } from "@/helpers/widgets";

export default {
  name: "Dashboard",
  components: {
    ...(() => {
      const context = require.context("@/components/widgets", false, /.*.vue/);
      return Object.fromEntries(
        context
          .keys()
          .map((k) => context(k).default)
          .filter((c) => c != undefined)
          .map((c) => [c.name, c])
      );
    })(),
  },
  data() {
    return {
      rows: [],
      widgets: [],
      template: [],
    };
  },
  computed: {
    ...mapGetters("devices", ["currentDevice"]),
    finca: function () {
      if (!this.currentDevice) return 'No hay instalaciones disponibles';
      return this.currentDevice.name
        ? this.currentDevice.name
        : 'No hay instalaciones disponibles';
    },
  },
  mounted() {
    this.loadItems();
    localStorage.setItem("screen", "dashboard");
  },
  methods: {
    getItems,
    loadItems() {
      if(!this.currentDevice) return;
      this.template = [];
      this.getItems(this.currentDevice)
        .then((data) => {
          if (data.ok) {
            const rows = data.widgets.filter(
              (item) => item.widget.type === "row"
            );
            rows.forEach(({widget}) => {
              this.template.push({
                type:'row',
                name: widget.name,
                children: [],
              });
            });
            const widgets = data.widgets.filter(
              (item) => item.widget.type !== "row"
            );
            widgets.forEach(({widget}) => {
              const idx = this.template.map((e) => e.name).indexOf(widget.container)
              this.template[idx].children.push(widget);
            });
          }
        })
        .catch(console.error);
    },
  },
  watch: {
    currentDevice: function () {
      if (this.currentDevice) this.loadItems();
    },
  },
};
</script>
